@use '@angular/material' as mat;

@include mat.core();

$brand-theme: mat.define-light-theme(
	(
		color: (
			primary: mat.define-palette(mat.$red-palette),
			accent: mat.define-palette(mat.$yellow-palette),
		),
	)
);

@include mat.all-component-themes($brand-theme);

@import 'reset';
@import 'utility';
@import 'mixin';
@import 'fonts';
@import 'change-angular-material-default-styles';

:root {
	--font-family: 'Programa Menu', sans-serif;
	font-family: var(--font-family);

	--max-width-desktop: 1320px;
	--max-width-desktop-venues: 1109px;
	--max-width-tablet: 600px;
	--content-primary: #e1173f;
	--content-primary-sidebar: var(--content-primary);
	--content-divider: #00000014;
	--content-border: hsla(0, 0%, 24%, 0.15);
	--content-border-black: hsla(0, 0%, 24%, 0.15);

	--content-txt: #3c3c3c;
	--content-txt-black: #3c3c3c;
	--content-txt-07: hsla(0, 0%, 24%, 0.7);
	--content-on-color: #ffffff;
	--card-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	--background-venue-card: white;

	--tag-secondary-bg: #f0f1f5;
	--tag-michelin-bg: #a22835;

	--header-background-color: white;
	--search-background-color: #f6f6f6;
	--search-border-color: #f0f0f0;
	--search-text-color: #3c3c3c;

	--card-background: #f7f7f7;
	--celebrations-background: #f8f8f8;

	--content-disabled: #ddd;
	--background-primary: linear-gradient(90deg, #cc092f, #b81570);
	--background-primary-hover: linear-gradient(90deg, #b81570, #cc092f);
	--background-disabled: #d8d8d8;
	--background-page: white;
	--background-tabs: #ececec;
	--content-tab-inactive: #b8b8b8;

	--form-label-color: #9d9d9d;
	--form-border-color: #9d9d9d;
	--form-border-focus-color: #3c3c3c;

	--input-login: #0000e9;

	--icon-color-red: #e1173f;
	--icon-color-black: #3c3c3c;
}

body.principal {
	--content-primary: #e1173f;
	--content-primary-sidebar: white;
	--content-divider: white;
	--content-border: white;
	--content-border-black: hsla(0, 0%, 24%, 0.15);

	--content-txt: #ffffff;
	--content-txt-black: #3c3c3c;
	--content-txt-07: hsla(0, 0%, 24%, 0.7);
	--content-on-color: #ffffff;
	--card-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	--background-venue-card: white;

	--tag-secondary-bg: #f0f1f5;
	--tag-michelin-bg: #a22835;

	--header-background-color: #810814;
	--search-background-color: #f6f6f6;
	--search-border-color: #f0f0f0;
	--search-text-color: #3c3c3c;

	--card-background: #f7f7f7;
	--celebrations-background: #ffffff;

	--content-disabled: #ddd;
	--background-primary: linear-gradient(90deg, #cc092f, #7f1318);
	--background-primary-hover: linear-gradient(90deg, #7f1318, #cc092f);
	--background-disabled: #d8d8d8;
	--background-page: #810814;
	--background-tabs: #ececec;
	--content-tab-inactive: #b8b8b8;

	--form-label-color: #c2c2c2;
	--form-border-color: #c2c2c2;
	--form-border-focus-color: #ffffff;

	--input-login: var(--content-primary);

	--icon-color-red: white;
	--icon-color-black: white;

	--accordion-title-color-active: white;
	--accordion-icon-color-active: white;
	--accordion-header-hover: var(--background-page);
}

body.centurion {
	--content-primary: #737373;
	--content-primary-sidebar: white;
	--content-divider: white;
	--content-border: white;
	--content-border-black: hsla(0, 0%, 24%, 0.15);

	--content-txt: #ffffff;
	--content-txt-black: #3c3c3c;
	--content-txt-07: hsla(0, 0%, 24%, 0.7);
	--content-on-color: #ffffff;
	--card-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	--background-venue-card: white;

	--tag-secondary-bg: #f0f1f5;
	--tag-michelin-bg: #a22835;

	--header-background-color: black;
	--search-background-color: #f6f6f6;
	--search-border-color: #f0f0f0;
	--search-text-color: #3c3c3c;

	--card-background: #f7f7f7;
	--celebrations-background: #ffffff;

	--content-disabled: #ddd;
	// --background-primary: linear-gradient(90deg, white, black);
	// --background-primary-hover: linear-gradient(90deg, black, white);

	--background-primary: linear-gradient(
		to right,
		rgba(153, 153, 153, 1) 0%,
		rgba(80, 80, 80, 1) 19%,
		rgba(62, 62, 62, 1) 52%,
		rgba(0, 0, 0, 1) 100%
	);
	--background-primary-hover: linear-gradient(
		to left,
		rgba(153, 153, 153, 1) 0%,
		rgba(80, 80, 80, 1) 19%,
		rgba(62, 62, 62, 1) 52%,
		rgba(0, 0, 0, 1) 100%
	);

	--background-disabled: #d8d8d8;
	--background-page: black;
	--background-tabs: #ececec;
	--content-tab-inactive: #b8b8b8;

	--form-label-color: #c2c2c2;
	--form-border-color: #c2c2c2;
	--form-border-focus-color: #ffffff;

	--input-login: var(--content-primary);

	--icon-color-red: white;
	--icon-color-black: white;

	--accordion-title-color-active: white;
	--accordion-icon-color-active: white;
	--accordion-header-hover: var(--background-page);
}

body.prime {
	--content-primary: #1f49c3;
	--content-primary-sidebar: white;
	--content-divider: white;
	--content-border: white;
	--content-border-black: hsla(0, 0%, 24%, 0.15);

	--content-txt: #ffffff;
	--content-txt-black: #3c3c3c;
	--content-txt-07: hsla(0, 0%, 24%, 0.7);
	--content-on-color: #ffffff;
	--card-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

	--background-venue-card: white;

	--tag-secondary-bg: #f0f1f5;
	--tag-michelin-bg: #a22835;

	--header-background-color: #0e1e64;
	--search-background-color: #f6f6f6;
	--search-border-color: #f0f0f0;
	--search-text-color: #3c3c3c;

	--card-background: #f7f7f7;
	--celebrations-background: #ffffff;

	--content-disabled: #ddd;
	// --background-primary: linear-gradient(90deg, white, black);
	// --background-primary-hover: linear-gradient(90deg, black, white);

	--background-primary: linear-gradient(
		to right,
		rgba(14, 30, 100, 1) 0%,
		rgba(10, 22, 75, 1) 19%,
		rgba(7, 15, 50, 1) 52%,
		rgba(3, 7, 25, 1) 100%
	);
	--background-primary-hover: linear-gradient(
		to left,
		rgba(14, 30, 100, 1) 0%,
		rgba(10, 22, 75, 1) 19%,
		rgba(7, 15, 50, 1) 52%,
		rgba(3, 7, 25, 1) 100%
	);

	--background-disabled: #d8d8d8;
	--background-page: #0e1e64;
	--background-tabs: #ececec;
	--content-tab-inactive: #b8b8b8;

	--form-label-color: #c2c2c2;
	--form-border-color: #c2c2c2;
	--form-border-focus-color: #ffffff;

	--input-login: var(--content-primary);

	--icon-color-red: white;
	--icon-color-black: white;

	--accordion-title-color-active: white;
	--accordion-icon-color-active: white;
	--accordion-header-hover: var(--background-page);
}

* {
	font-family: var(--font-family);
}

html,
body {
	color: var(--content-txt);
	background-color: var(--background-page);
}

.card-box-shadow {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px 20px;
	border-radius: 8px;
	box-shadow: var(--card-box-shadow);
	border: 1px solid #f0f0f0;

	&__item {
		display: flex;
		gap: 12px;
	}
}

.tablet-up-center {
	@include tablet-up {
		max-width: var(--max-width-tablet);
		margin: 0 auto;
	}
}

.copy-notification {
	position: absolute;
	top: -5px;
	right: 0;
	background-color: #323232;
	color: #fff;
	padding: 4px 8px;
	border-radius: 4px;
	font-size: 12px;
	white-space: nowrap;
}

.benefits-title {
	font-size: 18px;
	font-weight: 480;
	color: var(--content-txt);
}

// add .normal-button on mat-button
.normal-button.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base {
	border-radius: 20px;
	background-color: var(--background-venue-card);
	color: var(--content-primary);
	box-shadow: var(--card-box-shadow);
	width: 150px;
	font-size: 15px;
}
