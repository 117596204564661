// Changing Angular Material Default Styles
.mat-bottom-sheet-container {
	border-top-left-radius: 16px !important;
	border-top-right-radius: 16px !important;
	padding: 0 !important;
	overflow: hidden !important;
}

.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-button {
	letter-spacing: 0 !important;
}

.mat-expansion-panel-body {
	padding: 0 !important;
}

mat-spinner circle {
	stroke: var(--content-primary) !important;
	background: none !important;
}

svg {
	max-width: unset;
}

// stroked
.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-unthemed.mat-mdc-button-base {
	border-radius: 100px;
	height: 32px;
	border: 1px solid var(--content-border-black);
	color: var(--content-txt-black);
	font-size: 14px;
}

// stroked primary
.mdc-button.mdc-button--outlined.mat-mdc-outlined-button.mat-primary.mat-mdc-button-base {
	border-radius: 100px;
	height: 32px;
	border: 1px solid var(--content-primary);
	color: var(--content-primary);
	font-size: 14px;
}

// raised button and raised primary
.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-mdc-button-base {
	border-radius: 100px;
	width: 100%;
	height: 48px;
}

// raised button
.mdc-button.mdc-button--raised.mat-mdc-raised-button:not(.mat-primary):not(:disabled) {
	border: 1px solid var(--content-border-black);
	box-shadow: none;
	color: var(--content-primary);
	font-size: 16px;
	font-weight: 600;
}

// raised button primary and primary hover
.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-primary.mat-mdc-button-base {
	background: var(--background-primary);
	font-size: 16px;
	font-weight: 600;
}
.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-primary.mat-mdc-button-base:hover {
	background: var(--background-primary-hover);
}

// disabled button
.mdc-button.mdc-button--raised.mat-mdc-raised-button.mat-mdc-button-disabled.mat-unthemed.mat-mdc-button-base {
	color: var(--content-on-color);
	font-size: 16px;
	font-weight: 600;
	background-color: var(--background-disabled);
}

.mdc-dialog__surface {
	overflow: visible !important;
}
