@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-Bold-web.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-BoldItalic-web.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-Condensed-web.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-CondensedBold-web.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-Italic-web.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-Light-web.ttf') format('truetype');
	font-weight: 300; /* Normal light weight */
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-LightItalic-web.ttf') format('truetype');
	font-weight: 300; /* Normal light weight */
	font-style: italic;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-Medium-web.ttf') format('truetype');
	font-weight: 500; /* Medium weight */
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-MediumItalic-web.ttf') format('truetype');
	font-weight: 500; /* Medium weight */
	font-style: italic;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-Regular-web.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-SemiBold-web.ttf') format('truetype');
	font-weight: 600; /* Semi-bold weight */
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-SemiBoldItalic-web.ttf') format('truetype');
	font-weight: 600; /* Semi-bold weight */
	font-style: italic;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-Thin-web.ttf') format('truetype');
	font-weight: 100; /* Thin weight */
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-ThinItalic-web.ttf') format('truetype');
	font-weight: 100; /* Thin weight */
	font-style: italic;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-XBold-web.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Programa Menu';
	src: url('../assets/fonts/BradescoSans-XBoldItalic-web.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
